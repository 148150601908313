// used for students page, deactivate auth person, calendar, scheduled activity details, student landing page, students activities enrollment, update students
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { Storage } from '@ionic/storage';
import { ToastController } from '@ionic/angular';
import { Subject } from 'rxjs';

export class ApiS3Response {
  data: any
  img: any
}

@Injectable({
  providedIn: 'root'
})

export class StudentsService {
  constructor(public httpClient: HttpClient, public storage: Storage, public toastController: ToastController) { }

  public idToken: string;
  public pictures:Array<any> = [];
  public picturesLoaded:Array<any> = [];
  // fetch students
  getStudents() {
    console.log('getStudents()'); // todo: comment out logging as needed for prod
    return this.loadStudents().pipe(map(this.processStudents, this));
  }
  // fetch students' images
  async getStudentImage(studentId: number) {
    return this.httpClient.get<ApiS3Response>(environment.studentsUrl + studentId + '/images');
  }
  getStudentPictureURL(lausdId: any) {
    return this.httpClient.get<ApiS3Response>(environment.studentsUrl + lausdId + '/picture');
  }

  private loadStudents() {
    console.log('loadStudents() with url: ', environment.studentsUrl);
    return this.httpClient.get(environment.studentsUrl);
  }

  getStudentsForRetro(retroId) {
    return this.loadStudentsForRetro(retroId).pipe(map(this.processStudents, this));
  } 
  getStudentsForDeleteAttendance(deleteattendanceid) {
    return this.loadStudentsForDeleteAttendance(deleteattendanceid).pipe(map(this.processStudents, this));
  }   
  private loadStudentsForRetro(retroId) {
    let url = environment.studentsUrl + 'retro/' + retroId;
    return this.httpClient.get(url);
  }
  private loadStudentsForDeleteAttendance(deleteattendanceid) {
    let url = environment.studentsUrl + 'delete/' + deleteattendanceid;
    return this.httpClient.get(url);
  }

  getRetroFiles(retroId) {
    return this.loadRetroFiles(retroId);
  }

  private loadRetroFiles(retroId) {
    let url = environment.studentsUrl + 'retrofiles/' + retroId;
    return this.httpClient.get(url);
  }

  getStudentsForDelAttendance(delAttendanceId) {
    return this.loadStudentsForDeleteAttendance(delAttendanceId).pipe(map(this.processStudents, this));
  }  

  getDelAttendanceFiles(delAttendanceId) {    
    return this.loadDelAttendanceFiles(delAttendanceId);
  }

  private loadDelAttendanceFiles(delAttendanceId) {   
    let url = environment.studentsUrl + 'delAttendanceFiles/' + delAttendanceId;
    return this.httpClient.get(url);
  }

  // fetch students by search query
  getStudentsSearch(queryText) {
    console.log('getStudentsSearch()'); // todo: comment out logging as needed for prod
    return this.loadStudentsSearch(queryText).pipe(map(this.processStudentsSearch, this));
  }

  private loadStudentsSearch(queryText) {
    const profile = JSON.parse(window.localStorage.getItem('_ionicstorage/_ionickv/userSubject'));
    const adminRoles: number[] = [6,7,8];

    if (adminRoles.includes(profile.roles[0])) {
      console.log('loadStudentsSearch() with url: ', environment.studentsUrl + 'agency/' + profile.agency + '/searchonlanding/' + queryText);
      return this.httpClient.get(environment.studentsUrl + 'agency/' + profile.agency + '/searchonlanding/' + queryText);
    }
    else {
      console.log('loadStudentsSearch() with url: ', environment.studentsUrl + 'allagency/searchonlanding/' + queryText);
      return this.httpClient.get(environment.studentsUrl + 'allagency/searchonlanding/' + queryText);
    }
  }

  // POST request create new student
  createStudent(student) {
    console.log('createStudent() with url: ', environment.studentsUrl);
    return this.httpClient.post(environment.studentsUrl, student).subscribe(
      result => {
        // Handle result
        console.log(result);
      },
      error => {
        this.showError(error);
      },
      () => {
        this.showSuccess();
      }
    );
  }
  // PUT request update existing student
  updateStudent(student) {
    console.log('updateStudent() with url: ', environment.studentsUrl);
    // tslint:disable-next-line: max-line-length
    return this.httpClient.put(environment.studentsUrl + student.id, student).subscribe(
      result => {
        // Handle result
        console.log(result);
      },
      error => {
        this.showError(error);
      },
      () => {
        this.showSuccess();
      }
    );
  }
  // POST request create new auth person
  createAuthorizedPerson(person) {
    console.log('createAuthorizedPerson() with url: ', environment.authorizedPersonApiUrl);
    return this.httpClient.post(environment.authorizedPersonApiUrl, person).subscribe(
      result => {
        // Handle result
        console.log(result);
      },
      error => {
        this.showError(error);
      },
      () => {
        this.showSuccess();
      }
    );
  }
  // PUT request update existing auth person
  updateAuthorizedPerson(person) {
    console.log('updateAuthorizedPerson() with url: ', environment.authorizedPersonApiUrl);
    // tslint:disable-next-line: max-line-length
    return this.httpClient.put(environment.authorizedPersonApiUrl + person.id, person).subscribe(
      result => {
        // Handle result
        console.log(result);
      },
      error => {
        this.showError(error);
      },
      () => {
        this.showSuccess();
      }
    );
  }
  // DELETE request deactivate auth person
  deactivateAuthorizedPerson(person) {
    console.log('deactivateAuthorizedPerson() with url: ', environment.authorizedPersonApiUrl);
    // tslint:disable-next-line: max-line-length
    return this.httpClient.delete(environment.authorizedPersonApiUrl + person.id).subscribe(
      result => {
        // Handle result
        console.log(result);
      },
      error => {
        this.showError(error);
      },
      () => {
        this.showSuccess();
      }
    );
  }
  // withdraw student from scheduled activity
  withdrawStudent(studentActId) {
    console.log('withdrawStudent() with url: ', environment.studentsUrl + 'enroll/' + studentActId);
    // tslint:disable-next-line: max-line-length
    return this.httpClient.delete(environment.studentsUrl + 'enroll/' + studentActId).subscribe(
      result => {
        // Handle result
        console.log(result);
      },
      error => {
        this.showError(error);
      },
      () => {
        this.showSuccess();
      }
    );
  }
  // enroll student into school
  enrollStudent(student: any, modal: any) {
    console.log('enrollStudent()'); // todo: comment out logging as needed for prod
    return this.enrollStudentActivity(student, modal);
  }
  enrollMultiStudent(students: any) {
    console.log('enrollStudent()'); // todo: comment out logging as needed for prod
    return this.enrollMultiStudentActivity(students);
  }
  // enroll student into scheduled activity
  private enrollStudentActivity(student, modal):Subject<any>  {
    console.log(student);
    console.log('enrollStudentActivity() with url: ', environment.schoolsUrl + 'enroll');
    let subject = new Subject<any>();
    this.httpClient.put(environment.studentsUrl + 'enroll', student).subscribe(
      result => {
        // Handle result
        console.log(result);
        // if (result === true) {
        //   modal.dismiss({ 'success': true });
        // } else {
        //   this.showError('There is an error enrolling the student into this activity');
        // }
        subject.next(result);
      },
      error => {
        this.showError(error);
        subject.error(error);
      },
      () => {
        this.showSuccess();
        subject.complete();
      }
    );
    return subject;
  }
   private enrollMultiStudentActivity(students):Subject<any>  {
    console.log(students);
    console.log('enrollStudentActivity() with url: ', environment.schoolsUrl + 'enroll');
    let subject = new Subject<any>();
    this.httpClient.put(environment.studentsUrl + 'EnrollMultiStudent', students).subscribe(
      result => {
        // Handle result
        console.log(result);
        // if (result === true) {
        //   modal.dismiss({ 'success': true });
        // } else {
        //   this.showError('There is an error enrolling the student into this activity');
        // }
        subject.next(result);
      },
      error => {
        this.showError(error);
        subject.error(error);
      },
      () => {
        this.showSuccess();
        subject.complete();
      }
    );
    return subject;
  }

  assignAllStudentsToAgency(studentPrograms: any) {
    console.log('assignAllStudentsToAgency()'); // todo: comment out logging as needed for prod
    return this.assignAllStudents(studentPrograms);
  }


  private assignAllStudents(studentPrograms) {
    console.log('assignStudentAgency() with url: ', environment.studentsUrl + 'assignstudentstoagency');
    console.log(studentPrograms);
    let subject = new Subject<any>();
    this.httpClient.put(environment.studentsUrl + 'assignstudentstoagency', studentPrograms).subscribe(
      result => {
        // Handle result
        this.showSuccess();
        console.log(result);
        subject.next(result);
      },
      error => {
        this.showError(error);
      }
    );
    return subject;
  }
  assignStudentAgency(studentProgram: any, modal: any) {
    console.log('assignStudentAgency()'); // todo: comment out logging as needed for prod
    console.log('Modal :',modal);
    console.log('studentProgram :',studentProgram);
    return this.assignStudent(studentProgram, modal);
  }

  private assignStudent(studentProgram, modal: any) {
    console.log('assignStudentAgency() with url: ', environment.studentsUrl + 'assignagency');
    console.log(studentProgram);
    let subject = new Subject<any>();
    this.httpClient.put(environment.studentsUrl + 'assignagency', studentProgram).subscribe(
      result => {
        // Handle result
        this.showSuccess();
        console.log(result);
        subject.next(result);
      },
      error => {
        this.showError(error);
      }
    );
    return subject;
  }

  processStudents(data: any) {
    console.log('processStudents() with', data.length, 'rows');

    // generate the cds7Code from last 7 digits of the cdsCode.
    let cds7CodeString = '';
    data.forEach((row: any) => {
      if (row.cdsCode) {
        const cdsCodeString = row.cdsCode.toString();
        cds7CodeString = cdsCodeString.slice(cdsCodeString.length - 7);
        row.cds7Code = parseInt(cds7CodeString, 10);
      }
    });

    return data;
  }
  processStudentsSearch(data: any) {
    console.log('processStudentsSearch() with', data.length, 'rows');

    // generate the cds7Code from last 7 digits of the cdsCode.

    return data;
  }
  // fetch students by school ID and year/type
  getStudentsByYear(schoolId: number, type: number, year: number) {
    console.log('getStudentsBySchool()'); // todo: comment out logging as needed for prod
    return this.loadStudentsByYear(schoolId, type, year,true).pipe(map(this.processStudents, this));
  }
  getStudentsByYearInternal(schoolId: number, type: number, year: number) {
    console.log('getStudentsBySchool()'); // todo: comment out logging as needed for prod
    return this.loadStudentsByYear(schoolId, type, year,false).pipe(map(this.processStudents, this));
  }
  private loadStudentsByYear(schoolId: number, type: number, year: number, hasAgency:boolean) {
    const agencyid = this.profile.agency;
    console.log('loadStudentsBySchool() with url: ', environment.schoolStudentUrl + 'agency/' + agencyid
      + '/location/' + schoolId + '/' + type + '/' + year);
    this.storage.get('idToken').then(res => {
      this.idToken = res;
    });
    const header = new HttpHeaders().set(
      'Authorization',
      'Bearer ' + this.idToken
    );
    let url = environment.schoolStudentUrl + 'school/' + schoolId + '/' + type + '/' + year
    if(hasAgency){
      url = environment.schoolStudentUrl + 'agency/' + agencyid
    + '/location/' + schoolId + '/' + type + '/' + year
    }
    return this.httpClient.get(url, { headers: header });
  }

  getStudentsByMasterCalendar(schoolId: number, masterCalendarCode: string) {
    console.log('getStudentsByMasterCalendar()'); // todo: comment out logging as needed for prod
    return this.loadStudentsByMasterCalendar(schoolId, masterCalendarCode, true).pipe(map(this.processStudents, this));
  }

  getStudentsByScheduledActivityAndDate(scheduled_activity_id: number, attendanceDate: Date) {
    console.log('getStudentsByMasterCalendar()'); // todo: comment out logging as needed for prod
    return this.loadStudentsByScheduleActivityAndDate(scheduled_activity_id, attendanceDate).pipe(map(this.processStudents, this));
  }

  getStudentsByMasterCalendarAndNoAgency(schoolId: number, masterCalendarCode: string) {
    console.log('getStudentsByMasterCalendarAndNoAgency()'); // todo: comment out logging as needed for prod
    return this.loadStudentsByMasterCalendar(schoolId, masterCalendarCode, false).pipe(map(this.processStudents, this));
  }

  private loadStudentsByScheduleActivityAndDate(scheduled_activity_id: number, attendanceDate: Date) {

    this.storage.get('idToken').then(res => {
      this.idToken = res;
    });
    const header = new HttpHeaders().set(
      'Authorization',
      'Bearer ' + this.idToken
    );
    let url = environment.schoolStudentUrl + 'scheduleActivityId/' + scheduled_activity_id + '/attendanceDate/' + attendanceDate;
    return this.httpClient.get(url, { headers: header });
  }

  private loadStudentsByMasterCalendar(schoolId: number, masterCalendarCode:string, hasAgency:boolean) {
    const agencyid = this.profile.agency;
    console.log('loadStudentsByMasterCalendar() with url: ', environment.schoolStudentUrl + 'agency/' + agencyid
      + '/location/' + schoolId + '/mastercalendarCode/' + masterCalendarCode);
    this.storage.get('idToken').then(res => {
      this.idToken = res;
    });
    const header = new HttpHeaders().set(
      'Authorization',
      'Bearer ' + this.idToken
    );
    let url = environment.schoolStudentUrl + 'school/' + schoolId + '/mastercalendar/' + masterCalendarCode ;
    if(hasAgency){
      url = environment.schoolStudentUrl + 'agency/' + agencyid
    + '/location/' + schoolId + '/mastercalendar/' + masterCalendarCode ;
    }
    return this.httpClient.get(url, { headers: header });
  }

  getStudentsBAttendanceRetro(schoolId: number, masterCalendarCode: string) {
    console.log('getStudentsByMasterCalendarAndNoAgency()'); // todo: comment out logging as needed for prod
    return this.loadStudentsBAttendanceRetro(schoolId, masterCalendarCode, false).pipe(map(this.processStudents, this));
  }

  private loadStudentsBAttendanceRetro(schoolId: number, masterCalendarCode:string, hasAgency:boolean) {
    const agencyid = this.profile.agency;
    console.log('loadStudentsByMasterCalendar() with url: ', environment.schoolStudentUrl + 'attendanceretro/agency/' + agencyid
      + '/school/' + schoolId + '/mastercalendar/' + masterCalendarCode);

    let url = environment.schoolStudentUrl + 'attendanceretro/agency/' + agencyid
    + '/school/' + schoolId + '/mastercalendar/' + masterCalendarCode;

    return this.httpClient.get(url);
  }

  getStudentsByDeleteAttendance(schoolId: number, masterCalendarCode: string) {
    return this.loadStudentsByDelAttendance(schoolId, masterCalendarCode, false).pipe(map(this.processStudents, this));
  }

  private loadStudentsByDelAttendance(schoolId: number, masterCalendarCode:string, hasAgency:boolean) {
    const agencyid = this.profile.agency;
    console.log('loadStudentsByMasterCalendar() with url: ', environment.schoolStudentUrl + 'attendanceretro/agency/' + agencyid
      + '/school/' + schoolId + '/mastercalendar/' + masterCalendarCode);

    let url = environment.schoolStudentUrl + 'attendanceretro/agency/' + agencyid
    + '/school/' + schoolId + '/mastercalendar/' + masterCalendarCode;

    return this.httpClient.get(url);
  }

  submitStudentAttenanceRetro(attendanceRetro){
    let url = environment.studentsUrl + 'addattendanceretro/';
    attendanceRetro.agencyId = this.profile.agency;
    attendanceRetro.createBy = this.profile.name;
    attendanceRetro.createDate = new Date();
    attendanceRetro.status = "Pending";

    //post data first and then the file..
    return this.httpClient.post(url, attendanceRetro);
  }

  submitDeletedAttenance(deletedAttendance){
    let url = environment.studentsUrl + 'deleteattendance/';
    deletedAttendance.createBy = this.profile.name;
    deletedAttendance.createDate = new Date();
    deletedAttendance.status = "Pending";

    //post data first and then the file..
    return this.httpClient.post(url, deletedAttendance);
  }

  approveMultiAttenanceRetro(multiAttendanceRetros){
    let url = environment.studentsUrl + 'attendanceretromultiapproval/';
    multiAttendanceRetros.approvedBy = this.profile.name;
    //post data first and then the file..
    return this.httpClient.post(url, multiAttendanceRetros);
  }

  cancelAttendanceRetro(retroId){
    let url = environment.studentsUrl + 'cancelattendanceretro/' + retroId;
    return this.httpClient.post(url, retroId);
  }

  approveAttenanceRetro(attendanceRetro){
    let url = environment.studentsUrl + 'attendanceretroapproval/';
    attendanceRetro.approvedBy = this.profile.name;
    //post data first and then the file..
    return this.httpClient.post(url, attendanceRetro);
  }

  approveMultiDelAttendance(multiDelAttendance){
    let url = environment.studentsUrl + 'deleteattendancemultiapproval/';
    multiDelAttendance.approvedBy = this.profile.name;
    return this.httpClient.post(url, multiDelAttendance);
  }

  approveDelAttendance(delAttendance){
    let url = environment.studentsUrl + 'deleteattendanceapproval/';
    delAttendance.approvedBy = this.profile.name;
    return this.httpClient.post(url, delAttendance);
  }
  
  cancelDelAttendance(attendanceId){
    let url = environment.studentsUrl + 'canceldeleteattendance/' + attendanceId;
    return this.httpClient.post(url, attendanceId);
  }

  uploadManualAttendance(retroId: any, file:any){
    const formData = new FormData();
    formData.append('file', file);

    // const headers = new HttpHeaders().append('Content-Disposition', 'multipart/form-data');
    // headers.append('enctype', 'multipart/form-data'); //2
    // // headers.append('Content-Type', 'multipart/form-data'); //3
    const headers = new HttpHeaders().append('Content-Disposition', 'multipart/form-data');
    headers.append('enctype', 'multipart/form-data'); //2
    let url = environment.studentsUrl + 'addattendanceretrofile/' + retroId;
    return this.postFile(url, file);
  }

  postFile(url: string,  file: File) {
    let request = url;
    let formData = new FormData();
    formData.append("upload", file);
    // for (var key in body) {
    //   formData.append(key, body[key]);
    // }
    // const headers = new HttpHeaders().append('Content-Disposition', 'multipart/form-data');
    // headers.append('enctype', 'multipart/form-data'); //2

    return this.httpClient.post(request, formData);
  }

  async showSuccess() {
    const toast = await this.toastController.create({
      color: 'success',
      duration: 2500,
      message: 'Success!',
      showCloseButton: true,
      position: 'top'
    });
    await toast.present();
    setTimeout(function () {
      // window.location.reload();
    }, 1500);
  }

  async showError(error) {
    console.log(error);
    const toast = await this.toastController.create({
      color: 'danger',
      duration: 2500,
      message: 'Error!',
      showCloseButton: true,
      position: 'top'
    });
    await toast.present();
  }
  get profile() {
    return JSON.parse(window.localStorage.getItem('_ionicstorage/_ionickv/userSubject'));
  }


  private studentattendance(studentProgram, modal: any) {
    console.log('assignStudentAgency() with url: ', environment.studentsUrl + 'attendance');
    console.log(studentProgram);
    let subject = new Subject<any>();
    this.httpClient.put(environment.studentsUrl + 'attendance', studentProgram).subscribe(
      result => {
        // Handle result
        this.showSuccess();
        console.log(result);
        subject.next(result);
      },
      error => {
        this.showError(error);
      }
    );
    return subject;
  }

  createInititals(selecteduser: any) {

    let initials = "";

    var name = selecteduser.firstName + '#' + selecteduser.lastName;
    let str = name.split('#');
    for (let i = 0; i < str.length; i++) {
      initials += str[i].substr(0,1);
    }
    return initials.toUpperCase();
  }

  hasPicture(user){
    if (user.lausdId === '') {
      if(this.picturesLoaded[user.id] == null){
        this.picturesLoaded[user.id] = false;
      }
    }
    if(this.picturesLoaded[user.id] == null){
      this.picturesLoaded[user.id] = false;
      this.getStudentPictureURL(user.lausdId).subscribe(
        response => {
          if(response !== null && response !== undefined && response.img !== null && response.img !== undefined){
            this.pictures[user.lausdId] = response;
            this.picturesLoaded[user.id] = true;
         }
        },
        error => {
          this.showError(error);
        }
      );
    }
    //console.log('hasPicture(lausdId)',this.picturesLoaded[user.id]);
    return this.picturesLoaded[user.id];
  }

  studentPicture(lausdId){
    var response = this.pictures[lausdId];
    if(response !== null && response !== undefined && response.img !== undefined && response.img !== null){
      //console.log('response.img',response.img)
      return response.img;
    }
  }

  onImgError(user){
    this.picturesLoaded[user.id] = false;
   }

}
